<template>
  <div class="align">
    <div class="contentBlock">
      <div class="title">
        <h1>Продажа страховых продуктов.</h1>
      </div>
      <div class="body">
        <p>Продажа страховых продуктов. С полными правилами можно ознакомится <a href="pdf/infoBox.pdf" target="_blank">ЗДЕСЬ ( ссылка)</a></p>
        <p>Для оплаты страхового продукта перейдите на сайт ООО МКК ФИНМОЛЛ <a href='https://finmoll.ru/repayment' target='_blank'>https://finmoll.ru/repayment</a> и в поле «ID номер заемщика» введите свой номер договора, заменим первый 0 на цифру 3. Сумма оплаты должна быть равна стоимости страхового продукта.</p>


      </div>
      <div class="layoutFooter">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "saleBox",
  data(){
    return {
      code:'',
    }
  },
  mounted() {

  }

}
</script>

<style scoped>

.contentBlock{text-align: center}
#promoCode{font-size: 6vw;font-weight: bold;}
@media screen and (min-width: 760px){

}
@media screen and (min-width: 1200px){

}
</style>